import React from 'react';
import OverlayBox from './OverlayBox';
import { parseDate } from '../utils/parseDate';

const InternalNotificationModal = ({
  viewInternalNotificationModal,
  setViewInternalNotificationModal,
}) => {
  return (
    <OverlayBox
      header={viewInternalNotificationModal.notification.subject}
      active={viewInternalNotificationModal.active}
      close={() =>
        setViewInternalNotificationModal({
          ...viewInternalNotificationModal,
          active: false,
        })
      }
    >
      <p>
        <strong>Datum för hantering: </strong>
        {parseDate(viewInternalNotificationModal.notification.dateForHandling)}
      </p>
      <p>
        <strong>Hanteras av: </strong>
        {`${viewInternalNotificationModal.notification.recipientFirstName} ${viewInternalNotificationModal.notification.recipientLastName}`}
      </p>
      <p>
        <strong>Innehåll: </strong>
        {viewInternalNotificationModal.notification.textContent}
      </p>
    </OverlayBox>
  );
};

export default InternalNotificationModal;
