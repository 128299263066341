import React from 'react';
import OverlayBox from './OverlayBox';
import Button from './Button';

const DeleteNotificationModal = ({
  deleteNotificationPending,
  setDeleteNotificationPending,
  deleteNotification,
}) => {
  return (
    <OverlayBox
      header="Radera notis?"
      active={deleteNotificationPending.active}
      close={() =>
        setDeleteNotificationPending({
          ...deleteNotificationPending,
          active: false,
        })
      }
    >
      <p>
        Vill du <strong>permanent radera </strong>notisen?
      </p>
      <p>
        Det går <strong>inte </strong>att återskapa en raderad notis.
      </p>
      <Button
        med
        look="danger"
        onClick={() =>
          deleteNotification(deleteNotificationPending.notification)
        }
      >
        Radera
      </Button>
      <Button
        med
        onClick={() =>
          setDeleteNotificationPending({
            ...deleteNotificationPending,
            active: false,
          })
        }
      >
        Avbryt
      </Button>
    </OverlayBox>
  );
};

export default DeleteNotificationModal;
